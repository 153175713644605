import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, GridColumn } from '../atoms/Grid';
import { GridLeftRight } from './GridLeftRight';
import { CardItemVerticalLegacy } from './CardItemVerticalLegacy';

const imageMaxWidths = {
  1: {
    small_desktop: 839,
    large_desktop: 1110
  },
  2: {
    tablet: 462,
    small_desktop: 411,
    large_desktop: 544
  },
  3: {
    tablet: 305,
    small_desktop: 271,
    large_desktop: 359
  },
  4: {
    tablet: 226,
    small_desktop: 206,
    large_desktop: 266
  }
};

export const CardGridLeftRightLegacy = ({
  header,
  leftColumns,
  leftCardItems,
  right
}) => {
  const cardItems = (
    <GridRow key={`GridRow-${leftColumns}`}>
      {leftCardItems.map(cardItem => (
        <GridColumn
          width={[12, 12 / leftColumns]}
          key={`GridColumn-${cardItem.data.url}`}
        >
          <CardItemVerticalLegacy
            key={`CardItemVerticalLegacy-${cardItem.data.url}`}
            data={cardItem.data}
            isAboveTheFold={cardItem.isAboveTheFold}
            imageMaxWidths={imageMaxWidths[leftColumns]}
            gtmClickEvent={cardItem.imageMaxWidths}
          />
        </GridColumn>
      ))}
    </GridRow>
  );

  return <GridLeftRight header={header} left={[cardItems]} right={right} />;
};

CardGridLeftRightLegacy.defaultProps = {
  header: undefined,
  leftColumns: undefined
};

CardGridLeftRightLegacy.propTypes = {
  header: PropTypes.string,
  leftCardItems: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
      gtmClickEvent: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  leftColumns: PropTypes.oneOf([1, 2, 3, 4]),
  right: PropTypes.arrayOf(PropTypes.node).isRequired
};
